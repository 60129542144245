export default function useList($i18n) {
  const tableColumns = [
    { label:  'group_cameras.camera_proxy_group_is_assigned', key: 'camera_proxy_group_is_assigned', sortable: false },
    { label:  'id', key: 'id', sortable: false },
    { label: 'group_cameras.unit', key: 'units', sortable: false },
    { label: 'group_cameras.cameraID', key: 'cameraId', sortable: false },
    { label: 'group_cameras.name', key: 'name', sortable: false },
    { label: 'group_cameras.id_user', key: 'userId', sortable: false },
    { label: 'group_cameras.id_account', key: 'userId', sortable: false },
  ]

  const user = JSON.parse(window.localStorage.userData);

  if (user.is_master_admin) {
    tableColumns.splice(3, 0, { label: 'group_cameras.camera_type', key: 'camera_type', sortable: false },)
  }

  return {
    tableColumns,
  }
}
